import {Component} from "react"
import {connect} from "react-redux"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import {DataService} from "../../services/DataService"
import {withRouter} from "react-router"
import {IBet} from "../../models/bet.interface"
import Bet from "../../components/bet/Bet"



interface Props {
    global: any
    match?: any
}

interface State {
    bet: IBet
    isBet: boolean
}

export class _PrintScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {
            isBet: false,
            bet: {
                matches: [],
            },
        }
        this.dataService = DataService.getInstance()
        this.downloadTicket = this.downloadTicket.bind(this)
    }
    componentDidMount() {
        this.getData()
    }

    async getData() {
        store.dispatch(loading())
        const bet = await this.dataService.get(`bets/${this.props.match?.params?.id}`)
        this.setState({bet: bet, isBet: true})

        store.dispatch(ready())
    }

    downloadTicket() {}

    render() {
        return (
            <div className='container' style={{color: "white"}}>
                {this.state.isBet && this.state.bet && this.state.bet.round && (
                    <Bet bet={this.state.bet} download={false} />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const PrintScreen = withRouter(connect(mapStateToProps, mapDispatchToProps)(_PrintScreen))

export default PrintScreen
