import {Component} from "react"
import {connect} from "react-redux"
import {stringLengthHelper} from "../../helpers/global.helper"
import i18n from "../../i18n/i18n"
import {IBet} from "../../models/bet.interface"

import "./CardBet.scss"

const {t} = i18n

interface Props {
    global: any
    bet: IBet
}

interface State {}

export class _CardBet extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <div className='card-bet'>
                <div className='currency'>{this.props.bet.currency}</div>
                {this.props.bet.confirmations !== null ? (
                    <div className='status confirmed'>CONFIRMED</div>
                ) : (
                    <div className='status pending'>PENDING</div>
                )}
                <div className='text'>
                    <span className="country">{this.props.bet.round?.season.league.country.toUpperCase()}</span>
                    <strong>{stringLengthHelper(this.props.bet.round?.season.league.name || "", 42)}</strong>
                </div>
                <div className='text mt-2'>
                    <span>{t("round")}: </span>
                    {this.props.bet.round?.externalId}
                </div>
                <div className='text'>
                    <span>{t("address")}: </span>
                    {this.props.bet.depositAddress}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const CardBet = connect(mapStateToProps, mapDispatchToProps)(_CardBet)

export default CardBet
