import { CurrencyType } from "../../types/CurrencyType";

export const LOADING = 'LOADING';
export const READY = 'READY';
export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const SET_CURRENCY = 'SET_CURRENCY';


export const loading = () => {

    return {type: LOADING}
}

export const ready = () => {
 
    return {type: READY}
}


export const showModal = () => {
    return {type: SHOW}
}

export const hideModal = () => {
    return {type: HIDE}
}

export const setCurrency = (currency: CurrencyType) => {

    return {type: SET_CURRENCY}
}