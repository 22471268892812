import {CurrencyType} from "../../types/CurrencyType"
import {LOADING, READY, SHOW, HIDE, SET_CURRENCY} from "../actions/global"

const initalSatate = {
    loading: false,
    showModal: false,
    currency: localStorage.getItem("currency") || CurrencyType.BTC,
}

const globalReducer = (state = initalSatate, action: any) => {
    switch (action.type) {
        case READY:
            return {
                ...state,
                loading: false,
                currentTask: "",
            }

        case LOADING:
            return {
                ...state,
                loading: true,
            }

        case SHOW:
            return {
                ...state,
                showModal: true,
            }
        case HIDE:
            return {
                ...state,
                showModal: false,
            }
        case SET_CURRENCY:
            return {
                ...state,
                currency: action.currency,
            }

        default:
            return state
    }
}

export default globalReducer
