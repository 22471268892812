import {Component} from "react"
import i18n from "../../i18n/i18n"
import "./Bet.scss"
import {IBet} from "../../models/bet.interface"
import QR from "../../components/qr/QR"
import Fixture from "../../components/fixture/Fixture"
import {BsFiles} from "react-icons/bs"
import {DataService} from "../../services/DataService"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import {WebPlugin} from "@capacitor/core"
import env from "../../environments/environment"

const {t} = i18n

interface Props {
    bet: IBet
    download?: boolean
}

interface State {
    copied: boolean
    downloaded: boolean
}

export default class Bet extends Component<Props, State> {
    private dataService: DataService
    constructor(props: Props) {
        super(props)
        this.dataService = DataService.getInstance()
        this.downloadPDF = this.downloadPDF.bind(this)
        this.onCopy = this.onCopy.bind(this)
        this.state = {
            copied: false,
            downloaded: false
        }
    }

    async downloadPDF() {
        store.dispatch(loading())
        const pdf = await this.dataService.get(`ticket/generate/${this.props.bet.id}`)
        const link = document.createElement("a")
        link.setAttribute("href", `${env.API_URL}${pdf}`)
        link.setAttribute("download", pdf)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.setState({downloaded: true})
        setTimeout(() => {
            this.setState({downloaded: false})
        }, 5000);
        store.dispatch(ready())
    }
    parseStatistics(statistics: any) {
        return statistics.find((s: any) => s.currency == this.props.bet.currency)
    }

    async onCopy() {
        try {
            var textField = document.createElement("textarea")
            textField.innerText = this.props.bet.depositAddress || ""
            document.body.appendChild(textField)
            textField.select()
            document.execCommand("copy")
            textField.remove()
            this.setState({copied: true})
            setTimeout(() => {
                this.setState({copied: false})
            }, 3000)
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <div className='bet'>
                {this.props.bet.round && (
                    <Fixture round={this.props.bet.round} bet={this.props.bet} onlyRead={true}>
                        <div>
                            <div className='mt-4'>
                                STATUS: {this.props.bet.confirmations !== null ? " CONFIRMED" : " PENDING"}
                            </div>
                            <div className=''>
                                {t("currency").toUpperCase()}: {this.props.bet.currency}
                            </div>
                            <div>
                                {t("bet_amount").toUpperCase()}{" "}
                                {this.parseStatistics(this.props.bet.round.statistics)?.betAmount}{" "}
                                {this.props.bet.currency}
                            </div>

                            <div onClick={this.onCopy} className='deposit_address'>
                            <div className="address_text">{t("deposit_address").toUpperCase()} {this.props.bet.depositAddress}</div>
                                {this.state.copied ? (
                                    <div className='copied'>{t("copied")}</div>
                                ) : (
                                    <BsFiles className='copy-icon' />
                                )}
                            </div>

                            {this.props.bet.paymentAddress && (
                                <div>
                                    {t("your_address").toUpperCase()}: {this.props.bet.paymentAddress}
                                </div>
                            )}
                            {this.props.bet.confirmations !== null && (
                                <div>
                                    {t("confirmations").toUpperCase()}: {this.props.bet.confirmations}
                                </div>
                            )}
                            {this.props.bet.txHash && <div>HASH: {this.props.bet.txHash}</div>}

                            {this.props.bet.confirmations == null && (
                                <div>
                                    <div className='mt-4' onClick={this.onCopy}>
                                        <QR
                                            value={this.props.bet.depositAddress ? this.props.bet.depositAddress : ""}
                                        />
                                    </div>
                                    <div style={{textAlign: "justify", marginTop: 20}}>{t("remember")}</div>
                                </div>
                            )}

                            {this.props.download && !this.state.downloaded && (
                                <div
                                    style={{marginLeft: -5, marginRight: -5}}
                                    className='button button-primary mt-5'
                                    onClick={this.downloadPDF}>
                                    {t("download_ticket")}
                                </div>
                            )}
                            {
                                this.state.downloaded && <div className='downloaded'>{t("downloaded")}</div>
                            }
                        </div>
                    </Fixture>
                )}
            </div>
        )
    }
}
