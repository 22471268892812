import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import store from "../../store"
import {hideModal, setCurrency} from "../../store/actions/global"
import Modal from "react-bootstrap/Modal"
import "./ModalBase.scss"
import {CurrencyType} from "../../types/CurrencyType"

const {t} = i18n

interface Props {
    global: any
    showModal: boolean
}

interface State {}

export class _ModalBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.onClose = this.onClose.bind(this)
    }

    onClose() {
        store.dispatch(hideModal())
    }

    onChangeCurrency(currency: CurrencyType) {
        localStorage.setItem("currency", currency)
        store.dispatch(setCurrency(currency))
        window.location.reload()
    }

    render() {
        return (
            <Modal show={this.props.showModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("select_currency")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-options'>
                        <div
                            className={
                                this.props.global.currency == CurrencyType.BTC
                                    ? "modal-options-option active"
                                    : "modal-options-option"
                            }
                            onClick={() => this.onChangeCurrency(CurrencyType.BTC)}>
                            {CurrencyType.BTC}
                        </div>
                        <div
                            className={
                                this.props.global.currency == CurrencyType.RBTC
                                    ? "modal-options-option active"
                                    : "modal-options-option"
                            }
                            onClick={() => this.onChangeCurrency(CurrencyType.RBTC)}>
                            {CurrencyType.RBTC}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='button button-primary' onClick={this.onClose}>
                        Close
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
        showModal: state.global.showModal,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const ModalBase = connect(mapStateToProps, mapDispatchToProps)(_ModalBase)

export default ModalBase
