import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import "./Footer.scss"

const {t} = i18n

interface Props {
    global: any
}

interface State {}

export class _Footer extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    render() {
        return (
            <div className='footer'>
               Bit On Soccer 2014 | All rights reserved    
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const Footer = connect(mapStateToProps, mapDispatchToProps)(_Footer)

export default Footer





