import {Component} from "react"
import {connect} from "react-redux"
import {NavLink} from "react-router-dom"
import i18n from "../../i18n/i18n"
import env from "../../environments/environment"

import "./Header.scss"
import { CurrencyType } from "../../types/CurrencyType"
import store from "../../store"
import { showModal } from "../../store/actions/global"

const {t} = i18n

interface Props {
    global: any
}

interface State {
    lang: string
}

export class _Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.onChangeLanguage = this.onChangeLanguage.bind(this)
        this.state = {
            lang: "",
        }
    }

    componentDidMount() {
        const storeLanguage = localStorage.getItem("language")
        if (storeLanguage) {
            this.setState({lang: storeLanguage})
        } else {
            localStorage.setItem("language", "en")
            this.setState({lang: "en"})
        }
    }

    onChangeLanguage() {
        if (this.state.lang == "en") {
            localStorage.setItem("language", "es")
            window.location.reload()
        } else {
            localStorage.setItem("language", "en")
            window.location.reload()
        }
    }

    onChangeCurrency () {
       store.dispatch(showModal())
    }

    render() {
        return (
            <>
                <div className='top-message' onClick={this.onChangeCurrency}> {t("header_currency", {currency: `${this.props.global.currency} ${env.NETWORK != 'MAINET' ? '(TESTNET)' : ''}`})}</div>

                <div className='container'>
                    <div className='header'>
                        <div className='header-left'>
                            <NavLink className='header-button link' activeClassName='active' to={"/instructions"}>
                                {t("rules").toUpperCase()}
                            </NavLink>
                            <NavLink className='header-button link' activeClassName='active' to={"/history"}>
                                {t("history_bets").toUpperCase()}
                            </NavLink>
                        </div>
                        <div className='icon'>
                            <a href='/home'>
                                <img src='../assets/logo/logo2.svg' />
                            </a>
                        </div>
                        <div className='header-right'>
                            <NavLink className='header-button link' activeClassName='active' to={"/profile"}>
                                {t("profile").toUpperCase()}
                            </NavLink>
                            <div className='header-button' onClick={this.onChangeLanguage}>
                                {t(`${this.state.lang}`)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const Header = connect(mapStateToProps, mapDispatchToProps)(_Header)

export default Header
