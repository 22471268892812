import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import "./Fixture.scss"
import moment from "moment"

import {DataService} from "../../services/DataService"
import {IMatch, ITeams} from "../../models/match.interface"
import {IRound} from "../../models/round.interface"
import {IBet} from "../../models/bet.interface"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import {stringLengthHelper} from "../../helpers/global.helper"
import {CurrencyType} from "../../types/CurrencyType"

const {t} = i18n

interface Props {
    global: any
    bet?: IBet
    round: IRound
    onlyRead?: boolean
    children?: any
}

interface State {
    round: IRound
    matches: Array<IMatch>
    showMatches: boolean
    
}

export class _Fixture extends Component<Props, State> {
    private dataService: DataService
    private currency: CurrencyType
    constructor(props: Props) {
        super(props)
        this.state = {
            round: this.props.round,
            showMatches: this.props.onlyRead || false,
            matches: this.props.bet?.matches || this.props.round.matches,
        }
        this.currency = this.props.global.currency
        this.dataService = DataService.getInstance()
        this.onBet = this.onBet.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.openRound = this.openRound.bind(this)
    }
    
    componentDidUpdate(props:any) {
        if(props != this.props) {
            this.setState({
                round: this.props.round,
                showMatches: this.props.onlyRead || false,
                matches: this.props.bet?.matches || this.props.round.matches,
            })
        }
    }


    parseStatistics(statistics: any) {
        return statistics.find((s: any) => s.currency == this.currency)
    }

    async onBet() {
        if (this.state.round !== undefined) {
            store.dispatch(loading())

            const bet: IBet = {
                roundId: this.state.round.id,
                matches: this.state.round.matches,
                paymentAddress: "",
                currency: this.props.global.currency,
            }
            const response = await this.dataService.post("bets", bet)

            if (response) {
             
                window.location.assign(`/bet/id/${response.id}`)
            }

            //@TODO: show error un post bets

            //store.dispatch(ready())
        }
    }

    onSelect(match: IMatch, bet: number) {
        if (this.state.round !== undefined && !this.props.onlyRead) {
            let newData: IRound = this.state.round
            newData.matches = newData.matches.map((m: IMatch) => {
                if (m.externalId == match.externalId) {
                    match.teams.home.winner = false
                    match.teams.away.winner = false
                    if (bet !== 2) {
                        match.teams.home.winner = bet == 1
                        match.teams.away.winner = bet == 3
                    }
                }
                return m
            })
            this.setState({round: newData})
        }
    }

    openRound() {
        if (!this.props.onlyRead) {
            this.setState({showMatches: !this.state.showMatches})
        }
    }

    getDate() {
        const days = moment(this.state.round.startDate).diff(moment(), "days") || 0

        switch (true) {
            case days == 0:
                return <span>{t("date_message_case_0", {days: days.toString()})}</span>
                break
            case days <= 3 && days > 0:
                return <span>{t("date_message_case_1", {days: days.toString()})}</span>
                break
            case days <= 7 && days > 3:
                return <span>{t("date_message_case_2", {days: days.toString()})}</span>
                break
            case days <= 20 && days > 7:
                return <span>{t("date_message_case_3", {days: days.toString()})}</span>
                break
            case days > 21:
                return (
                    <span>
                        {t("date_message_case_4", {date: moment(this.state.round.startDate).format("DD/MM/YYYY")})}
                    </span>
                )
                break
            default:
                return <span>{t("date_message_case_default")}</span>
                break
        }
    }

    selectorClass(type: "home" | "away" | "draw", bet: ITeams, result: ITeams | undefined) {
        let isSelected = !bet.away.winner && !bet.home.winner
        let shouldBeSelected = result != undefined && !result.away.winner && !result.home.winner
        let hasResult = result != undefined
        if (type != "draw") {
            isSelected = bet[type]?.winner
            shouldBeSelected = result != undefined && result[type]?.winner
        }
        const match = hasResult && bet.away.winner == result?.away.winner && bet.home.winner == result?.home.winner
        return isSelected
            ? `selected ${match ? "check" : hasResult ? "error" : ""}`
            : `${shouldBeSelected ? "dashed" : ""}`
    }

    render() {
        const {children} = this.props
        return (
            <div>
                {this.state.round && (
                    <div>
                        <div className='fixture'>
                            <div className='fixture-header' onClick={this.openRound}>
                                <div className='fixture-header-left'>
                                    <div
                                        className='fixture-header-left-title'
                                        title={this.state.round.season.league.name}>
                                        <h1>
                                            <span className='country'>
                                                {this.state.round.season.league.country.toUpperCase()}
                                            </span>
                                            {this.state.round.season.league.name}
                                        </h1>
                                        <h3>{`${t("season")} ${this.state.round.season.externalId} - ${
                                            this.state.round.externalId
                                        }`}</h3>
                                        {!this.state.showMatches &&
                                            this.state.round.startDate &&
                                            !this.props.onlyRead && (
                                                <small className='start-date'>{this.getDate()}</small>
                                            )}
                                    </div>
                                </div>
                                <div className='fixture-header-right'>
                                    <img src={this.state.round.season.league.logo} className='logo' />
                                    <b>
                                        {this.parseStatistics(this.state.round.statistics)?.totalAmount}{" "}
                                        {this.props.global.currency}
                                    </b>
                                </div>
                            </div>
                            {this.state.showMatches && (
                                <div className='fixture-body'>
                                    {this.state.matches.map((match: IMatch) => {
                                        const roundMatch = this.state.round.matches.find(
                                            (m: IMatch) => m.externalId == match.externalId
                                        )
                                        let teams = undefined
                                        if (roundMatch?.goals.home != null) {
                                            teams = roundMatch?.teams
                                        }
                                        return (
                                            <div className='fixture-body-item' key={match.externalId}>
                                                <div className='selector'>
                                                    <span
                                                        className={this.selectorClass("home", match.teams, teams)}
                                                        onClick={() => this.onSelect(match, 1)}>
                                                        {t("home")}
                                                    </span>
                                                    <span
                                                        className={this.selectorClass("draw", match.teams, teams)}
                                                        onClick={() => this.onSelect(match, 2)}>
                                                        {t("draw")}
                                                    </span>
                                                    <span
                                                        className={this.selectorClass("away", match.teams, teams)}
                                                        onClick={() => this.onSelect(match, 3)}>
                                                        {t("away")}
                                                    </span>
                                                </div>

                                                <div className='teams'>
                                                    <div className='teams-left'>
                                                        <img className='teams-logo' src={match.teams.home.logo} />
                                                        <div className='teams-title'>
                                                            {match.teams.home.name}{" "}
                                                            {teams ? `(${roundMatch?.goals.home})` : ""}
                                                        </div>
                                                    </div>
                                                    <h1>VS</h1>
                                                    <div className='teams-right'>
                                                        <div className='teams-title'>
                                                            {teams ? `(${roundMatch?.goals.away})` : ""}{" "}
                                                            {match.teams.away.name}
                                                        </div>
                                                        <img className='teams-logo' src={match.teams.away.logo} />
                                                    </div>
                                                </div>

                                                <div className='date'>
                                                    <span>{moment(match.date).format("DD/MM/YYYY")}</span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {!this.props.onlyRead && (
                                        <div className='button' onClick={this.onBet}>
                                            {t("stake").toUpperCase()}
                                        </div>
                                    )}
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const Fixture = connect(mapStateToProps, mapDispatchToProps)(_Fixture)

export default Fixture
