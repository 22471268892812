import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import "./BetScreen.scss"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import {DataService} from "../../services/DataService"
import {withRouter} from "react-router"
import {IBet} from "../../models/bet.interface"
import Bet from "../../components/bet/Bet"
import {PDFViewer} from "@react-pdf/renderer"
import {Ticket} from "../../components/ticket/Ticket"

const {t} = i18n

interface Props {
    global: any
    match?: any
}

interface State {
    bet: IBet
    isBet: boolean
}

export class _BetScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {
            isBet: false,
            bet: {
                matches: [],
            },
        }
        this.dataService = DataService.getInstance()
 
    }
    componentDidMount() {
        store.dispatch(loading())
        this.getData()
        setInterval(() => {
            this.getData()
        }, 20000)
    }

    async getData() {

        if (this.props.match?.params?.address) {
            const bet = await this.dataService.get(`bets/deposit-address/${this.props.match?.params?.address}`)
            this.setState({bet: bet, isBet: true})
        } else {
            const bet = await this.dataService.get(`bets/${this.props.match?.params?.id}`)
            this.setState({bet: bet, isBet: true})
        }

        store.dispatch(ready())
    }

       render() {
        return (
            <div className='container'>
                {this.state.isBet && this.state.bet && this.state.bet.round && (
                    <Bet bet={this.state.bet} download={true} />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const BetScreen = withRouter(connect(mapStateToProps, mapDispatchToProps)(_BetScreen))

export default BetScreen
