import "./InstructionsScreen.scss"
import {connect} from "react-redux"
import {Component} from "react"
import {DataService} from "../../services/DataService"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import {t} from "i18next"

interface Props {
    global: any
}

interface State {}

export class _InstructionsScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {}
        this.dataService = DataService.getInstance()
    }

    componentDidMount() {}

    render() {
        return (
            <div className='container mt-5' style={{textAlign: "justify"}}>
                <div className='mt-5'>
                    <h5>{t("instructions.title_0")}</h5>
                    <p>{t("instructions.text_0")}</p>
                </div>

                <div className='mt-4'>
                    <h5>{t("instructions.title_1")}</h5>
                    <p>{t("instructions.text_1")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_2")}</h5>
                    <p>{t("instructions.text_2")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_3")}</h5>
                    <p>{t("instructions.text_3")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_4")}</h5>
                    <p>{t("instructions.text_4")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_5")}</h5>
                    <p>{t("instructions.text_5")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_6")}</h5>
                    <p>{t("instructions.text_6")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_7")}</h5>
                    <p>{t("instructions.text_7")}</p>
                </div>
                <div className='mt-4'>
                    <h5>{t("instructions.title_8")}</h5>
                    <p>{t("instructions.text_8")}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const InstructionsScreen = connect(mapStateToProps, mapDispatchToProps)(_InstructionsScreen)

export default InstructionsScreen
