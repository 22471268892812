const es = {
    es: "ESPAÑOL",
    hello: "Hola (Español)",
    app_name: "Bit On Soccer",
    welcome_to: "BIENVENIDO A",
    BOS: "BitOnSoccer",
    carousell_message: "No hay apuestas disponibles en este momento. ¡Pero no te desanimes, pronto habrá nuevas apuestas!",
    login: "Ingresar",
    your_bets: "Apuestas",
    profile: "Perfil",
    rules: "Reglas",
    stake: "Apostar",
    payments: "Pagos",
    downloaded: "Ticket descargado, revisa las descargas de tu navegador",
    select_currency: "Selecciona la moneda con la cual quieres operar en Bit On Soccer (BOS)",
    header_currency: "Estás apostando con {{currency}}, puedes cambiar de moneda haciendo click aquí",
    input_your_address: "Ingresa tu dirección de {{currency}} para ver todas tus apuestas",
    input_addres_placeholder: "Ingresa tu dirección",
    history_message: "Estas son las apuestas mas recientes en BOS sobre {{currency}}",
    address: "Dirección",
    deposit_address: "Dirección de deposito:",
    your_address: "Tu dirección",
    confirmations: "Confirmaciones",
    season: "Season",
    round: "Ronda",
    league: "Liga",
    search: "Buscar",
    home: "LOCAL",
    away: "VISITANTE",
    draw: "EMPATE",
    available_bets: "RONDAS DISPONIBLES",
    history_bets: "HISTORIAL",
    download_ticket: "Descargar Ticket",
    remember: "Recuerda que tu apuesta se concretará cuando hagas el pago de la misma desde una billetera. Es importante que solo transfieras el monto indicado, sino se considerará una apuesta  no valida. El proceso de tu pago puede tardar algunos minutos.",
    bet_details: "Detalles de tu apuesta para ",
    currency: "Moneda",
    remember_details:
        "Recuerda que puedes consultar el estado de tu apuesta y detalles de la misma desde el siguiente ",
    match_details: "Detalle de partidos:",
    status: "Estado:",
    copied: "Copiado!",
    start_date: "Comienzo de la Ronda",
    bet_amount: "Monto de la apuesta:",
    date_message_case_0: "Esta apuesta cierra en menos de 24 horas!",
    date_message_case_1: "¡Solo quedan {{days}} días para hacer tu apuesta!",
    date_message_case_2: "Tienes {{days}} días para hacer tu apuesta, ¡No pierdas tiempo!",
    date_message_case_3: "Aún faltan {{days}} días para que comience esta ronda, tienes tiempo para hacer tu apuesta.",
    date_message_case_4: "Esta ronda comienza el {{date}}, pero ya puedes hacer tu apuesta.",
    date_message_case_default: "La fecha de inicio de esta ronda no está disponible en este momento.",
    instructions: {
        title_0: "¿Que es Bit on Soccer (BOS)?",
        text_0: "Bit On Soccer es un sitio de apuestas descentralizadas, donde puedes hacer apuestas sobre fútbol con criptomonedas, rápido, simple y seguro. No necesitar crear una sesión ni identificarte, tus criptomonedas son tu huella.",
        title_1: "¿Sobre que apostar?",
        text_1: "Puedes apostar sobre las rondas disponibles y habilitadas, estas aparecerán en la sección APUESTAS DISPONIBLES.",
        title_2: "¿Cómo apostar?",
        text_2: "Para apostar deberás determinar los resultados de los diferentes partidos que componen la ronda, eligiendo si gana el equipo local, el visitante o empatan. Tu apuesta es sobre el resultado total de la ronda, por lo que solo se divide el pozo entre los jugadores que hayan obtenido el cien por ciento de los aciertos.",
        title_3: "¿Cómo pago mi apuesta?",
        text_3: "Una vez que hayas seleccionado los resultados y presiones APOSTAR, serás redirigido a un Ticket de pago. En el Ticket tendrás la dirección a la que tienes que hacer el pago y un código QR para escanearlo desde cualquier billetera de criptomonedas. IMPORTANTE: El código QR no contiene el monto de la apuesta, por lo que tendrás que ingresarlo manualmente al momento de hacer la transacción.",
        title_4: "¿Cuánto tarda en procesarse el pago de mi apuesta?",
        text_4: "Una vez que hayas realizado el pago desde una billetera de criptomonedas este puede tardar en asentarse en nuestros registros entre una hora y un día, no te preocupes, siempre podrás ver el proceso.",
        title_5: "¿Cómo veo mis apuestas?",
        text_5: "Puedes ver todas tus apuestas desde la sección TUS APUESTAS, ahí puedes ingresar la dirección con la que has hecho la apuesta y las mismas se listaran. También puedes seguir el enlace que te adjuntamos en tu Ticket de Depósito. Tanto el asiento del pago como si lo que te paguemos en caso de que ganes se verán reflejados ahí.",
        title_6: "¿Cuánto gano en una apuesta?",
        text_6: "Si has acertado el resultado de todos los partidos de una ronda ganarás el 80% del pozo acumulado. Ten en cuenta que si hay varios ganadores el pozo se divide en partes iguales entre los ganadores.",
        title_7: "¿Cómo pagan mi apuesta?",
        text_7: "Los pagos se realizan en forma automática al finalizar la ronda, con un depósito del importe ganado a la dirección de criptomonedas con la que hayas hecho la apuesta.",
        title_8: "¿Es seguro apostar en BOS?",
        text_8: "En Bit on Soccer hacemos que tu apuesta sea única e irrepetible, tienes una Dirección y un Hash de tu apuesta. Es una apuesta descentralizada por lo que nadie podrá saber que eres tú quien esta apostando, y los pagos llegarán a tu billetera. Es un proceso transparente, que se basa en los máximos estándares de seguridad de las criptomonedas.",
    },
}

export default es
