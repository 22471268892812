import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import QRCode from "react-qr-code"
import "./QR.scss"

const {t} = i18n

interface Props {
    global: any
    value: string
}

interface State {}

export class _QR extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    onClick() {
        console.log("onClick")
    }

    render() {
        return (
            <div className='QR'>
                <QRCode bgColor="#1f1d1f" fgColor="#ffffff" size={200} value={this.props.value} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const QR = connect(mapStateToProps, mapDispatchToProps)(_QR)

export default QR
