const en = {
    en: "ENGLISH",
    hello: "Hello (English)",
    welcome_to: "WELCOME TO",
    BOS: "BitOnSoccer",
    carousell_message: "There are no bets available at this time. But do not be discouraged, soon there will be new bets!",
    app_name: "Bit On Soccer",
    login: "Login",
    your_bets: "Bets",
    profile: "Profile",
    rules: "Rules",
    stake: "Bet",
    payments: "Payments",
    downloaded: "Ticket downloaded, check your browser downloads",
    select_currency: "Select the currency you want to trade in Bit On Soccer (BOS)",
    header_currency: "You are betting on {{currency}}, you can change currency by clicking here",
    input_your_address: "Enter your {{currency}} address to see all your bets",
    input_addres_placeholder: "Enter your address",
    history_message: "These are the most recent bets on BOS on {{currency}}",
    address: "Address",
    round: "Ronund",
    league: "League",
    search: "Search",
    home: "HOME",
    away: "AWAY",
    draw: "DRAW",
    available_bets: "AVAILABLE ROUNDS",
    history_bets: "HISTORY",
    download_ticket: "Download Ticket",
    remember:"Remember that your bet will be finalized when you make the payment from a wallet. It is important that you only transfer the indicated amount, otherwise it will be considered an invalid bet. The process of your payment may take a few minutes.",
    bet_details: "Details of your bet for ",
    currency: "Currency",
    remember_details: "Remember that you can check the status of your bet and its details from the following ",
    match_details: "Match details:",
    status: "Status:",
    start_date: "Start of the round",
    bet_amount: "Bet amount:",
    copied: "Copied!",
    deposit_address: "Deposit Address:",
    your_address: "Your Address",
    confirmations: "Confirmations",
    deposit_amount: "Bet Amount",
    season: "Season",
    date_message_case_0: "This bet closes in less than 24 hours!",
    date_message_case_1: "Only {{days}} days left to place your bet!",
    date_message_case_2: "You have {{days}} days to place your bet, don't waste time!",
    date_message_case_3: "There are still {{days}} days until this round starts, you have time to place your bet.",
    date_message_case_4: "This round starts on {{date}}, but you can now place your bet.",
    date_message_case_default: "The start date for this round is not available at this time.",
    instructions: {
        title_0: "What is Bit on Soccer (BOS)?",
        text_0: "Bit On Soccer is a decentralized betting site, where you can make bets on soccer with cryptocurrencies, fast, simple and safe. You do not need to create a session or identify yourself, your cryptocurrencies are your footprint.",
        title_1: "What to bet on?",
        text_1: "You can bet on the available and enabled rounds, these will appear in the AVAILABLE BETS section.",
        title_2: "How to bet?",
        text_2: "To bet you must determine the results of the different matches that make up the round, choosing whether the local team wins, the visitor or draw. Your bet is on the total result of the round, so the pot is only divided between the players who have obtained one hundred percent of the successes.",
        title_3: "How do I pay my bet?",
        text_3: "Once you have selected the results and press BET, you will be redirected to a Payment Ticket. In the Ticket you will have the address to which you have to make the payment and a QR code to scan it from any cryptocurrency wallet. IMPORTANT: The code QR does not contain the amount of the bet, so you will have to enter it manually at the time of making the transaction.",
        title_4: "How long does it take to process my bet payment?",
        text_4: "Once you have made the payment from a cryptocurrency wallet it can take between an hour and a day to settle in our records, don't worry, you will always be able to see the process.",
        title_5: "How do I see my bets?",
        text_5: "You can see all your bets from the YOUR BETS section, there you can enter the address with which you made the bet and they will be listed. You can also follow the link that we attach to your Deposit Ticket. Both the payment entry and if what we pay you in case you win will be reflected there.",
        title_6: "How much do I win on a bet?",
        text_6: "If you have guessed right the result of all the matches of a round you will win 80% of the accumulated pot. Keep in mind that if there are several winners the pot is divided equally among the winners.",
        title_7: "How do they pay my bet?",
        text_7: "Payments are made automatically at the end of the round, with a deposit of the amount won to the cryptocurrency address with which you placed the bet.",
        title_8: "Is it safe to bet on BOS?",
        text_8: "In Bit on Soccer we make your bet unique and unrepeatable, you have an Address and a Hash of your bet. It is a decentralized bet so no one can know that it is you who is betting, and the payments will reach your wallet. It is a transparent process, which is based on the highest security standards of cryptocurrencies.",
    },
}

export default en
