import "./HistoryScreen.scss"
import {connect} from "react-redux"
import {Component} from "react"
import {DataService} from "../../services/DataService"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import i18n from "../../i18n/i18n"
import {IBet} from "../../models/bet.interface"
import CardBet from "../../components/card/CardBet"

interface Props {
    global: any
}

interface State {
    bets: Array<IBet>
}

const {t} = i18n

export class _HistoryScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {bets: []}
        this.dataService = DataService.getInstance()
    }

    componentDidMount() {
        this.getBetsData()
    }

    async getBetsData() {
        store.dispatch(loading())
        const bets = await this.dataService.get(`bets?currency=${this.props.global.currency}`)
        this.setState({bets: bets})
        store.dispatch(ready())
    }

    render() {
        return (
            <div className='container' style={{minHeight: "100%"}}>
                <div className='hisotry'>
                    <p>{t("history_message", {currency: this.props.global.currency})}</p>
                    <div>
                        {this.state.bets.map((bet: IBet) => {
                            return (
                                <div key={bet.id}>
                                    <CardBet bet={bet} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const HistoryScreen = connect(mapStateToProps, mapDispatchToProps)(_HistoryScreen)

export default HistoryScreen
