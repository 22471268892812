import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner'
import '../loader/LoaderBase.scss'



interface State {
    
}

interface Props {
    loading?: boolean
}

export class _LoaderBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
       
    }

    componentDidMount(){
        console.log(this.props.loading)
    }

    render () {
        return (
            
           <div className={this.props.loading ? 'wrapper' : 'hidden'}>
               <div className='background'></div>
                <Spinner animation="grow" variant="light" />
           </div>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        loading: state.global.loading,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const LoaderBase = connect(mapStateToProps, mapDispatchToProps)(_LoaderBase)

export default LoaderBase