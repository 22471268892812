import {Component} from "react"
import {connect} from "react-redux"
import i18n from "../../i18n/i18n"
import Carousel from "react-bootstrap/Carousel"
import "./Carousel.scss"
import {IRound} from "../../models/round.interface"

const {t} = i18n

interface Props {
    global: any
    rounds: Array<IRound>
}

interface State {
    rounds: Array<IRound>
}

export class _CarouselBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {rounds: []}
    }

    render() {
        return (
            <div>
                <Carousel
                    style={{marginTop: 35}}
                    nextIcon={<span className='next'>NEXT</span>}
                    prevIcon={<span className='prev'>PREV</span>}>
                    <Carousel.Item>
                        <img className='carousel-image' src='..\assets\banners\1.png' alt='1' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='carousel-image' src='..\assets\banners\2.png' alt='1' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='carousel-image' src='..\assets\banners\3.png' alt='1' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='carousel-image' src='..\assets\banners\5.png' alt='1' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='carousel-image' src='..\assets\banners\6.png' alt='1' />
                    </Carousel.Item>
                </Carousel>
                {/* {this.props.rounds && this.props.rounds.length > 0 && (
                    <div>
                        <Carousel
                            style={{marginTop: 35}}
                            nextIcon={<span className='next'>NEXT</span>}
                            prevIcon={<span className='prev'>PREV</span>}>
                            {this.props.rounds.map((round: IRound) => {
                                return (
                                    <Carousel.Item key={round.id}>
                                        <img className='carousel-image' src='..\assets\banners\banner-4.png' alt='1' />
                                        <Carousel.Caption>
                                            <h3>{round.season.league.name}</h3>
                                            <h1>{round.season.league.country.toUpperCase()}</h1>
                                            <p>
                                                {t("season")} {round.season.externalId} - {round.externalId}
                                            </p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </div>
                )} */}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const CarouselBase = connect(mapStateToProps, mapDispatchToProps)(_CarouselBase)

export default CarouselBase
