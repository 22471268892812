import "./ProfileScreen.scss"
import {connect} from "react-redux"
import {Component} from "react"
import {DataService} from "../../services/DataService"

import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import i18n from "../../i18n/i18n"
import {IMatch} from "../../models/match.interface"
import {IBet} from "../../models/bet.interface"
import Bet from "../../components/bet/Bet"

interface Props {
    global: any
}

interface State {
    address: string
    search: boolean
    bets: Array<any>
    showBets: boolean
}

const {t} = i18n

export class _ProfileScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {address: "", search: false, bets: [], showBets: false}
        this.dataService = DataService.getInstance()
        this.onChange = this.onChange.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.openBet = this.openBet.bind(this)
    }

    componentDidMount() {
        this.setAdddress()
    }

    async setAdddress() {
        const address = localStorage.getItem(`address_${this.props.global.currency}`) || ""
        this.setState({address: address}, () => {
            if (this.state.address.length > 30) {
                this.setState({search: true})
                this.onSearch()
            } else {
                this.setState({search: false})
            }
        })
    }

    onChange(event: any, field: any) {
        this.setState(
            {
                ...this.state,
                [field]: event.target.value,
            },
            () => {
                if (this.state.address.length > 30) {
                    this.setState({search: true})
                } else {
                    this.setState({search: false})
                }
            }
        )
    }

    async onSearch() {
        
        localStorage.setItem(`address_${this.props.global.currency}`, this.state.address.toLowerCase())
        store.dispatch(loading())
        const response = await this.dataService.get(`bets/address/${this.state.address.toLowerCase()}`)
        this.setState({showBets: true, bets: response})
        const sortLeagues = response.sort((a: any, b: any) => {
            return a.id > b.id ? -1 : 1
        })

        store.dispatch(ready())
    }

    openBet(id: string) {
        window.location.assign(`/bet/id/${id}`)
    }

    render() {
        return (
            <div className='container' style={{minHeight: "100%"}}>
                <div className='profile'>
                    <p>{t("input_your_address", {currency: this.props.global.currency})}</p>
                    <input
                        className='input'
                        placeholder={t("input_addres_placeholder")}
                        type={"text"}
                        value={this.state.address}
                        onChange={(value) => this.onChange(value, "address")}
                    />

                    {this.state.search && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                                alignContent: "flex-end",
                                justifyContent: "flex-end",
                            }}>
                            <div className='button button-primary mt-2' onClick={this.onSearch}>
                                {t("search")}
                            </div>
                        </div>
                    )}
                    {this.state.showBets && this.state.bets.length == 0 && (
                        <div className='mt-4'>Parece que no hay resultados para tu búsuqeda...</div>
                    )}

                    {this.state.bets.map((bet: IBet) => {
                        return <Bet bet={bet} />
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const ProfileScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfileScreen)

export default ProfileScreen
