import axios from "axios";
import env from "../environments/environment";


export class DataService {
    private static instance: DataService;
    private baseUrl =  env.API_URL

    public static getInstance() {
        if (!DataService.instance) {
            DataService.instance = new DataService()
        }
        return DataService.instance
    }


    async get(url:string) {
        const response = await axios.get(`${this.baseUrl}/${url}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    }

    async post(url:string, body:any) {
        const response = await axios.post(`${this.baseUrl}/${url}`, body, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        return response.data;
    }
}
