import {Redirect, Route} from "react-router-dom"
import {IonApp, IonContent, IonPage, IonRouterOutlet, setupIonicReact} from "@ionic/react"
import {IonReactRouter} from "@ionic/react-router"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"
import "./styles/global.scss"
import ModalBase from "./components/modal/ModalBase"
import HomeScreen from "./pages/home/HomeScreen"
import LoaderBase from "./components/loader/LoaderBase"
import BetScreen from "./pages/bet/BetScreen"
import Header from "./components/header/Header"
import HistoryScreen from "./pages/history/HistoryScreen"
import Footer from "./components/footer/Footer"
import InstructionsScreen from "./pages/instructions/InstructionsScreen"
import ProfileScreen from "./pages/profile/ProfileScreen"
import PrintScreen from "./pages/print/PrintScreen"
import {Component} from "react"
import {connect} from "react-redux"
import store from "./store"
import {setCurrency} from "./store/actions/global"
import {CurrencyType} from "./types/CurrencyType"

setupIonicReact()

interface Props {
    global: any
}

export class _App extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    componentDidMount() {}

    render() {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <IonPage>
                            <IonContent fullscreen className='background'>
                                <Header />
                                <Route exact path='/home'>
                                    <HomeScreen />
                                </Route>
                                <Route exact path='/bet/id/:id'>
                                    <BetScreen />
                                </Route>
                                <Route exact path='/bet/address/:address'>
                                    <BetScreen />
                                </Route>

                                <Route exact path='/profile'>
                                    <ProfileScreen />
                                </Route>

                                <Route exact path='/history'>
                                    <HistoryScreen />
                                </Route>
                                <Route exact path='/instructions'>
                                    <InstructionsScreen />
                                </Route>
                                <Route exact path='/'>
                                    <Redirect to='/home' />
                                </Route>
                                <Footer />
                            </IonContent>
                        </IonPage>
                        <Route exact path='/bet/print/:id'>
                        <IonPage>
                            <IonContent className='background'>
                                
                                    <PrintScreen />
                                
                            </IonContent>
                        </IonPage>
                        </Route>
                    </IonRouterOutlet>
                </IonReactRouter>
                <LoaderBase />
                <ModalBase />
            </IonApp>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
        currency: state.currency,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const App = connect(mapStateToProps, mapDispatchToProps)(_App)

export default App
