import "./HomeScreen.scss"
import {connect} from "react-redux"
import {Component} from "react"
import CarouselBase from "../../components/carousel/Carousel"
import {DataService} from "../../services/DataService"
import Fixture from "../../components/fixture/Fixture"
import {IRound} from "../../models/round.interface"
import store from "../../store"
import {loading, ready} from "../../store/actions/global"
import moment from "moment"


interface Props {
    global: any
}

interface State {
    rounds: Array<IRound>
    newBet: boolean
}

export class _HomeScreen extends Component<Props, State> {
    private dataService: DataService

    constructor(props: Props) {
        super(props)
        this.state = {rounds: [], newBet: false}
        this.dataService = DataService.getInstance()
    }

    componentDidMount() {
        this.getRoundsData()

    }

    async getRoundsData() {
        store.dispatch(loading())
        const rounds = await this.dataService.get("rounds/current")
        const sortRounds = rounds.sort((a: any, b: any) => {
           
            return moment(a.startDate).diff(moment(b.startDate), "days")
        })
        this.setState({
            rounds: sortRounds,
        })
        store.dispatch(ready())
    }

  

      render() {
        return (
            <div className='container' style={{minHeight: "100%"}}>
                <div >
                    <CarouselBase rounds={this.state.rounds}/>
                </div>

                <div>
                    <div>
                        {this.state.rounds.map((round: IRound) => {
                            return (
                                <section key={round.id}>
                                    <Fixture round={round} />
                                </section>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        global: state.global,
    }
}

const mapDispatchToProps = (dispatch: any) => ({})

const HomeScreen = connect(mapStateToProps, mapDispatchToProps)(_HomeScreen)

export default HomeScreen
